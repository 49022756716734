import { Loader } from '@googlemaps/js-api-loader';

// const apiKey = 'AIzaSyDN7jKIxNXgkRj9ygYIDsGWs1Vl0Gy8gDk';
const apiKey = 'AIzaSyDOqYud38oaZms1EpdoLsxoBA63oDD-wes';

// TODO consider how to load this API key as an env var
const loader = new Loader({
  apiKey,
  version: "weekly",
  libraries: ["places"]
});

export async function loadGoogle() {
  return await loader.load();
}

export async function loadGeocoder() {
  const google = await loadGoogle();
  return new google.maps.Geocoder();
}
