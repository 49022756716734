let LocalStorage = {
  listeners: {},
  on(key, handler) {
    let listeners = LocalStorage.listeners[key] || [];
    listeners.push(handler);
    LocalStorage.listeners[key] = listeners;
  },
  off(key, handler) {
    let listeners = LocalStorage.listeners[key] || [];
    listeners = listeners.filter(h => h !== handler);
    LocalStorage.listeners[key] = listeners;
  },
  set(key, value) {
    window.localStorage.setItem(key, value);
    let listeners = LocalStorage.listeners[key] || [];
    console.log(`Notifying ${listeners.length} listeners`)
    listeners.forEach(handler => handler(value));
  },
  get(key) {
    let item = window.localStorage.getItem(key);
    try {
      return JSON.parse(item);
    } catch(e) {
      return item;
    }
  }
};

export {LocalStorage};
