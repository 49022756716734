<template>
  <div class="modal modal-md" ref="tourModal">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-fullscreen-xl-down">
      <div class="modal-content">
        <div class="modal-body p-4">
          <div class="p-4">
            <div class="text-center mt-4 mb-2 fw-bold h1">
              Scarborough Reval Map
            </div>
            <div class="text-center text-muted mb-4 small text-uppercase">
              version 2 - 2024
            </div>
            <p>
              Scarborough is preparing for another revaluation in the spring. Updated valuation notices are
              expected to start going out in May of 2024, and we plan to incorporate that data into the reval map as
              soon as it's publicly available.
            </p>
            <p>
              In the meantime, we're working on enhancements to the map, so check back soon for updates. If you have
              any suggestions, you can email us at
              <a target="_blank" href="mailto:reval@scarborough.me">reval@scarborough.me</a>. We'd love to hear your
              ideas!
            </p>
            <p>
              As we make changes to the tool, it's possible we may accidentally break something from time to time, so
              also please reach out to report any bugs or errors in our calculations. Thanks!
            </p>
            <div class="text-center p-4">
              <button type="button" class="btn btn-lg btn-primary" @click="hide">
                Got it. Take me to the map!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const HIDE_KEY = 'hideWelcomePopup';
export default {
  data() {
    return {
      storageHandler: () => { this.showModal() },
      dontShowAgain: true,
      modal: null
    };
  },
  methods: {
    hide() {
      this.dontShowAgain = true;
      this.modal.hide();
    },
    showModal() {
      if (!this.LocalStorage.get(HIDE_KEY)) {
        if (!this.modal) {
          this.modal = new this.bootstrap.Modal(this.$refs.tourModal);
          this.$refs.tourModal.addEventListener('hidden.bs.modal', () => {
            if (this.dontShowAgain) {
              this.LocalStorage.set(HIDE_KEY, true);
            }
          });
        }
        this.modal.show();
      }
    }
  },
  mounted() {
    if (!this.complete && !window.showedStaffPortal) {
      this.$nextTick(() => this.showModal());
      window.showedStaffPortal = true;
    }
    this.LocalStorage.on(HIDE_KEY, this.storageHandler);
  },
  beforeUnmount() {
    this.LocalStorage.off(HIDE_KEY, this.storageHandler);
  }
}
</script>
