export const zones = [
  "B1 - Beach Mixed-Use District",
  "CMU1 - Coastal Mixed Use",
  "CR1 - Coastal Residential",
  "B2 - Regional Business",
  "B3 - General Business",
  "BOR - Business Office Research",
  "CPD - Cross Roads Planned Development District",
  "HPZ - Haigis Parkway",
  "I - Industrial",
  "LI - Light Industrial",
  "R2 - Residential 2",
  "R3 - Residential 3",
  "R4 - Residential 4",
  "R4A - Residential 4A",
  "TND - Traditional Neighborhood",
  "RF - Rural Residence, Farming",
  "RFM - Rural Residence, Farming, Manufactured Housing",
  "RH - Running Hill Mixed Use",
  "RH2 - Running Hill Transition",
  "RP - Resource Protection",
  "RPO - Residential Professional Office",
  "TVC - Town and Village Centers",
  "TVC2 - Town and Village Centers Transition",
  "TVC3 - Town and Village Centers Fringe",
  "TVC4- Town and Village Centers 4 District",
  "VR2 - Village Residential 2",
  "VR4 - Village Residential 4"
];

export function lookupZoneDescription(index) {
  index = parseInt(index);
  return zones[index - 1];
}
