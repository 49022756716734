<template>
  <div class="container-fluid p-4">
    <div class="row">
      <div class="col-sm-12 mb-3">
        <label class="form-label">
          Search by address or owner
        </label>
        <input type="text" class="form-control" v-model="query"/>
        <div class="small list-group search-results mt-1 border-top border-bottom" v-if="query.length != 0">
          <div v-if="queryMatches.length == 0" class="list-group-item">
            No matches found
          </div>
          <template v-else>
            <a
              v-for="match in queryMatches"
              :key="match.id"
              class="list-group-item list-group-item-action"
              :href="`/#/${match.getProperty('property').id}`"
            >
              <div>{{ match.getProperty('source').address }}</div>
              <div v-if="match.getProperty('source').owner1" class="small text-muted">
                {{ match.getProperty('source').owner1 }}
              </div>
              <div v-if="match.getProperty('source').owner2" class="small text-muted">
                {{ match.getProperty('source').owner2 }}
              </div>
            </a>
          </template>
        </div>
      </div>

      <div class="col-sm-12 mb-3">
        <label class="form-label flex-grow-1">Use</label>
        <select class="form-control form-select" v-model="filters.use">
          <option :value="null">All Uses</option>
          <option v-for="use in allUses" :key="use.value" :value="use.value">{{ use.value }} ({{ formatNumber(use.count) }})</option>
        </select>
      </div>

      <div class="col-sm-12 mb-3">
        <label class="form-label flex-grow-1">Zone</label>
        <select class="form-control form-select" v-model="filters.zone">
          <option :value="null">All Zones</option>
          <option v-for="zone in allZones" :key="zone.value" :value="zone.value">{{ zone.value }} ({{ formatNumber(zone.count) }})</option>
        </select>
      </div>

      <div class="col-sm-12 mb-3">
        <label class="form-label flex-grow-1">Subdivision</label>
        <select class="form-control form-select" v-model="filters.subdivision">
          <option :value="null">All Subdivisions</option>
          <option v-for="subdivision in allSubdivisions" :key="subdivision.value" :value="subdivision.value">{{ subdivision.value }} ({{ formatNumber(subdivision.count) }})</option>
        </select>
      </div>

      <div class="d-none col-sm-12 mb-3">
        <div class="d-flex flex-row align-items-center">
          <label class="form-label flex-grow-1">Neighborhood</label>
          <div v-if="neighborhood.length === 0" class="small form-label">
            Showing all
          </div>
          <div v-else class="small">
            <a class="btn btn-flat" href="#" @click.prevent="neighborhood = []; updateFilter;">
              Clear
            </a>
          </div>
        </div>
        <select ref="neighborhoodSelect" multiple class="form-select" v-model="neighborhood" @change="updateFilter">
          <option v-for="neighborhood in neighborhoods" :key="neighborhood">{{ neighborhood }}</option>
        </select>
      </div>

      <div class="col-sm-12 mb-3">
        <RangeInput
          label="Year Built"
          v-model:low="filters.year.min"
          v-model:high="filters.year.max"
          :min="yearBuiltRange.min"
          :max="yearBuiltRange.max"
        >
          <template #pre-form>
            <div class="form-check small mb-3">
              <input type="checkbox" class="form-check-input" v-model="filters.year.unknown" />
              <label class="form-check-label">Include Unknown</label>
            </div>
          </template>
        </RangeInput>
      </div>
      <div class="col-sm-12 mb-3">
        <RangeInput
          label="Valuation"
          format="currency"
          v-model:low="filters.valuation.min"
          v-model:high="filters.valuation.max"
          :min="valuationRange.min"
          :max="valuationRange.max"
        />
      </div>
      <div class="col-sm-12 mb-3">
        <div class="form-check small">
          <input type="checkbox" class="form-check-input" v-model="onlyShowTaxBillsDecreased" :disabled="onlyShowTaxBillsStillDecreased" />
          <label class="form-check-label">
            Only tax bills that decreased after the 2018 reval
          </label>
        </div>
      </div>
      <div class="col-sm-12 mb-3">
        <div class="form-check small">
          <input type="checkbox" class="form-check-input" v-model="onlyShowTaxBillsStillDecreased" />
          <label class="form-check-label">
            Only tax bills that are still below the pre-2018 reval
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import RangeInput from "@/lib/RangeInput.vue";

  export default {
    components: {RangeInput},
    props: ['map','selectFeature','filterFeatures'],
    data() {
      return {
        query: '',
        queryMatches: [],
        neighborhood: [],
        allNeighborhoods: true,
        minLotSize: '',
        maxLotSize: '',
        minSquareFootage: '',
        maxSquareFootage: '',
        onlyShowTaxBillsDecreased: false,
        onlyShowTaxBillsStillDecreased: false,
        filters: {
          use: null,
          zone: null,
          subdivision: null,
          year: {
            min: null,
            max: null,
            unknown: true
          },
          valuation: {
            min: null,
            max: null
          }
        },
        searchTask: 0
      };
    },
    mounted() {
      // TODO Re-implement this
      // var selects = $(this.$el.find('select');
      // M.FormSelect.init(selects);
      //
      // this.map.data.forEach((feature) => {
      //   var property = feature.getProperty('source');
      //   if (property.useDescription) {
      //     this.useDescriptions[property.useDescription] = (this.useDescriptions[property.useDescription] || 0) + 1;
      //   }
      //   if (property.neighborhood) {
      //     this.neighborhoods[property.neighborhood] = (this.neighborhoods[property.neighborhood] || 0) + 1;
      //   }
      // });
    },
    watch: {
      query: function() {
        clearTimeout(this.searchTask);
        this.searchTask = setTimeout(() => {
          const lcQuery = this.query.toLowerCase();
          function isMatch(term) {
            return term && term.toLowerCase().indexOf(lcQuery) >= 0;
          }
          let queryMatches = [];
          this.map.data.forEach((feature) => {
            let property = feature.getProperty('source');
            if (isMatch(property.address) || isMatch(property.owner1) || isMatch(property.owner2)) {
              queryMatches.push(feature);
            }
          });
          this.queryMatches = queryMatches;
        }, 200);
      },
      onlyShowTaxBillsStillDecreased() {
        if (this.onlyShowTaxBillsStillDecreased) {
          this.onlyShowTaxBillsDecreased = true;
        }
        this.updateFilter();
      },
      onlyShowTaxBillsDecreased() {
        this.updateFilter();
      },
      filters: {
        deep: true,
        handler() {
          this.updateFilter();
        }
      }
    },
    computed: {
      yearBuiltRange() {
        let min = 2023;
        let max = 2000;
        this.map.data.forEach(feature => {
          const property = feature.getProperty('property');
          if (property && !isNaN(property.yearBuilt)) {
            min = Math.min(property.yearBuilt, min);
            max = Math.max(property.yearBuilt, max);
          }
        });
        return {min, max};
      },
      valuationRange() {
        let min = 0;
        let max = 0;
        this.map.data.forEach(feature => {
          const property = feature.getProperty('property');
          if (property && !isNaN(property.newValue)) {
            min = Math.min(property.newValue, min);
            max = Math.max(property.newValue, max);
          }
        });
        return {min, max};
      },
      allUses: function() {
        let uses = {};
        this.map.data.forEach((feature) => {
          let property = feature.getProperty('property');
          if (property && property.useDescription) {
            uses[property.useDescription] = uses[property.useDescription] || {
              value: property.useDescription,
              count: 0
            };
            uses[property.useDescription].count++;
          }
        });
        return Object.values(uses).sort((u1, u2) => u2.count - u1.count);
      },
      allZones: function() {
        let zones = {};
        this.map.data.forEach((feature) => {
          let property = feature.getProperty('property');
          if (property && property.zoneDescription) {
            zones[property.zoneDescription] = zones[property.zoneDescription] || {
              value: property.zoneDescription,
              count: 0
            };
            zones[property.zoneDescription].count++;
          }
        });
        return Object.values(zones).sort((u1, u2) => u2.count - u1.count);
      },
      allSubdivisions: function() {
        let subdivisions = {};
        this.map.data.forEach((feature) => {
          let property = feature.getProperty('property');
          if (property && property.subdivision) {
            subdivisions[property.subdivision] = subdivisions[property.subdivision] || {
              value: property.subdivision,
              count: 0
            };
            subdivisions[property.subdivision].count++;
          }
        });
        return Object.values(subdivisions).sort((u1, u2) => u2.count - u1.count);
      },
      neighborhoods: function() {
        var neighborhoods = {};
        this.map.data.forEach((feature) => {
          var property = feature.getProperty('source');
          if (property.neighborhood) {
            neighborhoods[property.neighborhood] = true;
          }
        });
        return Object.keys(neighborhoods).sort();
      }
    },
    methods: {
      filterValue(value, key, range) {
        const filter = this.filters[key];
        if (value == null) {
          return filter.unknown !== false;
        }
        if (filter.min !== null && filter.min > range.min) {
          if (value < filter.min) {
            return false;
          }
        }
        if (filter.max !== null && filter.max < range.max) {
          if (value > filter.max) {
            return false;
          }
        }
        return true;
      },
      updateFilter: function() {
        clearTimeout(this.searchTask);
        this.searchTask = setTimeout(() => {
          this.map.data.forEach(feature => {
            const property = feature.getProperty('property');
            let match = true;
            if (property) {
              if (this.filters.use && property.useDescription !== this.filters.use) match = false;
              else if (this.filters.zone && property.zoneDescription !== this.filters.zone) match = false;
              else if (this.filters.subdivision && property.subdivision !== this.filters.subdivision) match = false;
              else if (!this.filterValue(property.yearBuilt, 'year', this.yearBuiltRange)) match = false;
              else if (!this.filterValue(property.newValue, 'valuation', this.valuationRange)) match = false;
              // else if ((this.filters.year.min || 0) > this.yearBuiltRange.min && ((property.yearBuilt || 0) < this.filters.year.min)) match = false;
              // else if ((this.filters.year.max || 9999999) < this.yearBuiltRange.max && ((property.yearBuilt || 9999999) > this.filters.year.max)) match = false;
              // else if ((this.filters.valuation.min || 0) > this.valuationRange.min && property.newValue && property.newValue < this.filters.valuation.min) match = false;
              // else if ((this.filters.valuation.max || 999999999) < this.valuationRange.max && property.newValue && property.newValue > this.filters.valuation.max) match = false;
              else if (this.minLotSize && (!property.lotSize || parseFloat(property.lotSize) < this.minLotSize)) match = false;
              else if (this.maxLotSize && (!property.lotSize || parseFloat(property.lotSize) > this.maxLotSize)) match = false;
              else if (this.minSquareFootage && (!property.squareFootage || property.squareFootage < this.minSquareFootage)) match = false;
              else if (this.maxSquareFootage && (!property.squareFootage || property.squareFootage > this.maxSquareFootage)) match = false;

              if (this.onlyShowTaxBillsStillDecreased) {
                console.log(`${property.address}: ${property.currentTaxBill} from ${property.oldTaxBill}`);
                if (property.currentTaxBill > property.oldTaxBill) {
                  match = false;
                }
              } else if (this.onlyShowTaxBillsDecreased) {
                if (property.newTaxBill > property.oldTaxBill) {
                  match = false;
                }
              }
            }
            if (match) feature.removeProperty('hidden');
            else feature.setProperty('hidden', true);
          });
        }, 500);
      }
    }
  }
</script>

<style>
.search-results {
  max-height: 200px;
  overflow: auto;
}
.form-label {
  font-size: 13px;
  font-weight: 400;
}
.search-results .list-group-item:first-child {
  border-top: 0 !important;
}
.search-results .list-group-item:last-child {
  border-bottom: 0 !important;
}
ul.dropdown-content.select-dropdown.multiple-select-dropdown {
  max-height: 200px !important;
}
</style>
