import { createApp } from 'vue';
import { createRouter, createWebHashHistory } from 'vue-router';
import VueGtagPlugin from 'vue-gtag';
import { loadGoogle } from "@/google";

import "bootstrap-icons/font/bootstrap-icons.css";
import * as bootstrap from 'bootstrap';
import Popper from 'popper.js';

import './app.scss';
import './assets/static.css';

import moment from "moment";

import {LocalStorage} from "@/reval/models/LocalStorage";
import App from './App.vue';
import HomeView from './reval/HomeView.vue'

loadGoogle().then(google => {
  const map = new google.maps.Map(document.getElementById('map'), {
    center: {lat:43.6083, lng:-70.3976},
    zoom: 12,
    zoomControl: true,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: true,
    mapTypeControlOptions: {
      position: google.maps.ControlPosition.LEFT_BOTTOM
    }
  });

  let app = createApp(App);

  app.mixin({
    computed: {
      bootstrap() {
        return bootstrap;
      },
      Popper() {
        return Popper;
      },
      moment() {
        return moment;
      },
      LocalStorage() {
        return LocalStorage;
      }
    },
    methods: {
      formatAddress(address) {
        return `${address.streetNumber} ${address.street}`;
      },
      formatDate(date, valueIfNull) {
        if (date && date.match && date.match(/\d\d\d\d-\d\d-\d\d/)) {
          return moment(date, 'YYYY-MM-DD').format('MMM D, YYYY');
        }
        if (date && date.toDate) {
          date = date.toDate();
        }
        if (date && date.toLocaleString) {
          return date.toLocaleString();
        }
        return date || valueIfNull || '(No date)';
      },
      formatDuration(duration) {
        let d = moment.duration(duration);
        let hours = d.hours();
        let minutes = d.minutes();
        let seconds = d.seconds();
        return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
      },
      formatCount(count, singular, plural) {
        return `${count} ${count === 1 ? singular : (plural || `${singular}s`)}`;
      },
      formatNumber(number, noun, pluralForm) {
        if (typeof number !== 'number') {
          return '---';
        } else {
          let n = number.toLocaleString();
          if (!noun) {
            return n;
          } else if (number === 1) {
            return `${n} ${noun}`;
          } else {
            return `${n} ${pluralForm || `${noun}s`}`;
          }
        }
      },
      formatDollars(amount) {
        return (amount).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      },
      formatPhone(phone) {
        return phone && phone.replace(/[^\d]/g, '')
        .replace(/^(\d{6})/, '$1-')
        .replace(/^(\d{3})/, '($1) ');
      },
      formatCurrency(value) {
        if (typeof value === 'number' || typeof value === 'string') {
          return (value).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0
          });
        } else {
          return '---';
        }
      }
    }
  });

  const router = createRouter({
    history: createWebHashHistory(),
    routes: [
      {
        name: 'home',
        path: '/',
        component: HomeView,
        props: {map, google}
      },
      {
        name: 'property',
        path: '/:propertyId',
        component: HomeView,
        props: route => {
          return {
            map,
            google,
            selectedPropertyId: route.params.propertyId
          };
        }
      }
    ]
  });

  app.use(router);

  app.use(VueGtagPlugin, {
    // config: { id: 'G-G2VNY7QRSC' }
    config: { id: 'UA-148380205-1' }
  }, router);

  app.mount('#app');

});
