<template>
  <div>
    <div class="form-label">{{ label }}</div>
    <slot name="pre-form"/>
    <div class="position-relative slider-range">
      <input type="range" v-model="lowValue" :min="min" :max="max" class="form-range"/>
      <input type="range" v-model="highValue" :min="min" :max="max" class="form-range"/>
    </div>
    <div class="small text-muted d-flex flex-row justify-content-between">
      <div>{{ formatValue(lowValue) }}</div>
      <div>{{ formatValue(highValue) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    low: Number,
    high: Number,
    min: {
      type: Number,
      default: () => 0
    },
    max: {
      type: Number
    },
    format: String
  },
  methods: {
    formatValue(value) {
      if (this.format === 'currency') {
        return this.formatCurrency(value);
      } else if (this.format === 'number') {
        return this.formatNumber(value);
      } else {
        return value;
      }
    }
  },
  computed: {
    lowValue: {
      get() {
        return this.low || this.min;
      },
      set(v) {
        this.$emit('update:low', parseInt(v));
        if (v > this.highValue) {
          this.highValue = v;
        }
      }
    },
    highValue: {
      get() {
        return this.high || this.max;
      },
      set(v) {
        this.$emit('update:high', parseInt(v));
        if (v < this.lowValue) {
          this.lowValue = v;
        }
      }
    }
  }
}
</script>
