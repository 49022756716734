<template>
  <div class="d-flex flex-column align-items-stretch h-100 overflow-auto">
    <div v-for="group in links" :key="group.label" class="px-4 mt-4">
      <div class="fw-bold mb-3">
        {{ group.label }}
      </div>
      <div class="list-group">
        <a v-for="link in group.links" :key="link.url" target="_blank" class="list-group-item-action list-group-item" :href="link.url">
          {{ link.label }}
        </a>
      </div>
    </div>
    <div class="flex-grow-1"></div>
    <div class="small px-4 pt-4 mt-4 border-top">
      <div class="d-flex flex-row align-items-center">
        <button type="button" class="btn btn-outline-primary btn-sm" @click="LocalStorage.set('hideWelcomePopup', false)">
          <i class="bi-info-circle me-1" /> Welcome to Version 2!
        </button>
        <div class="ms-auto text-muted">
          <div>v2.0.1 10/18/23</div>
        </div>
      </div>
      <div class="mt-2">
        If you have suggestions or questions about this tool, please feel free to
        <a href="mailto:reval@scarborough.me" target="_blank">email us</a>!
      </div>
    </div>
    <div class="small rounded mt-3 py-2 px-4 text-muted bg-light border-top">
      <div class="small">
        This tool is not affiliated with the Town of Scarborough.
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },
    computed: {
      links() {
        return [
          {
            label: 'Town Resources',
            links: [
              {
                label: '2024 Revaluation',
                url: 'https://www.scarboroughmaine.org/departments/assessing/property-revaluation'
              },
              {
                label: 'Correction Request Form',
                url: 'https://docs.google.com/forms/d/e/1FAIpQLSeLS6ULX2_sE2tQVyekKBNUFmxC1sCGzmqmgyUDZxqQCDlkBw/viewform?entry.1630003683='
              },
              {
                label: 'Assessor Website',
                url: 'http://www.scarboroughmaine.org/departments/assessing'
              },
              {
                label: 'Self Service Tax Portal',
                url: 'https://scarboroughme.munisselfservice.com/css/citizens/RealEstate/Default.aspx?mode=new'
              }
            ]
          },
          {
            label: 'Data Sources',
            links: [
              {
                label: 'KRT Proposed Values (2019)',
                url: 'https://www.krtappraisal.com/dist/pdf/owner-scarborough.pdf'
              },
              {
                label: 'Vision Database',
                url: 'https://gis.vgsi.com/scarboroughme'
              }
            ]
          }
        ];
      }
    }
  }
</script>

<style>
  .link-list .link-list-header {
    font-weight:500;
  }
</style>
