import taxRates from './tax_rates.json';
import {lookupZoneDescription} from "@/reval/models/zones";

function capitalize(s) {
  if (!s) {
    return s;
  } else {
    return s.split(/ +/g).map(part => part.length <= 1 ? part : `${part[0].toUpperCase()}${part.substring(1).toLowerCase()}`).join(' ');
  }
}

export default class Property {

  static OLD_TAX_RATE = 16.49;
  static NEW_TAX_RATE = 14.7;
  static CURRENT_TAX_RATE = 15.97;

  constructor(data) {
     this.data = data;
     // Cached properties
    this.isLandTrust = !!(data.owner1 || '').match(/SCARBOROUGH LAND (CONSERVATION )?TRUST/i);
    this.isTownProperty = !!(data.owner1 || '').match(/Scarborough, Town Of/i);
    this.address = capitalize(this.data.address);
    this.neighborhood = capitalize(this.data.neighborhood);
    this.subdivision = capitalize(this.data.subdivision);
    this.zoneDescription = lookupZoneDescription(this.data.zone);
    this.useDescription = capitalize(this.data.useDescription);
    this.owners = [this.data.owner1, this.data.owner2].filter(owner => !!owner).map(capitalize);

    this.currentTaxBill = Math.max(0, (this.finalValue - this.exemptions) * Property.CURRENT_TAX_RATE / 1000);
    this.oldTaxBill = Math.max(0, (this.oldValue - this.exemptions) * Property.OLD_TAX_RATE / 1000);
    this.newTaxBill = Math.max(0, (this.finalValue - this.exemptions) * Property.NEW_TAX_RATE / 1000);
    this.taxBillChange = this.oldTaxBill <= 0 ? 0 : (this.newTaxBill / this.oldTaxBill - 1);
    this.valueChange = this.oldValue ? (this.finalValue / this.oldValue - 1) : this.finalValue ? 1 : 0;

    let year = taxRates.endYear;
    this.taxBillHistory = taxRates.taxRates.map(rate => {
      let valuation = year <= 2018 ? this.oldValue : this.newValue;
      return {
        year: year--,
        rate,
        valuation,
        bill: (valuation - this.exemptions) * rate / 1000
      };
    }).filter(entry => entry.year >= 2008);
    this.averageTaxIncrease = Math.pow(this.taxBillHistory[0].bill / this.taxBillHistory[10].bill, 1 / 10) - 1;
    this.averageTaxIncrease6Years = Math.pow(this.taxBillHistory[0].bill / this.taxBillHistory[6].bill, 1 / 6) - 1;
    this.averageTaxIncrease4Years = Math.pow(this.taxBillHistory[0].bill / this.taxBillHistory[4].bill, 1 / 4) - 1;
  }

   get id() {
    return this.data.parcelId;
   }

   // get address() {
   //   return capitalize(this.data.address);
   // }

   get isPublic() {
    return this.isLandTrust || this.isTownProperty;
   }

   get isNew() {
    return this.data.isNew;
   }

   get oldValue() {
    return this.data.oldValue || 0;
   }

   get newValue() {
    return this.data.newValue || 0;
   }

   get finalValue() {
    return this.data.finalValue || this.newValue;
   }

   get exemptions() {
    return this.data.exemptions || 0;
   }

   get landValue() {
    return this.data.landValue || 0;
   }

   get buildingValue() {
    return this.data.buildingValue || 0;
   }

   // get currentTaxBill() {
   //  return Math.max(0, (this.finalValue - this.exemptions) * Property.CURRENT_TAX_RATE / 1000);
   // }
   //
   // get oldTaxBill() {
   //  return Math.max(0, (this.oldValue - this.exemptions) * Property.OLD_TAX_RATE / 1000);
   // }
   //
   // get newTaxBill() {
   //  return Math.max(0, (this.finalValue - this.exemptions) * Property.NEW_TAX_RATE / 1000);
   // }
   //
   // get taxBillChange() {
   //  const oldTaxBill = this.oldTaxBill;
   //  const newTaxBill = this.newTaxBill;
   //  return oldTaxBill <= 0 ? 0 : (newTaxBill / oldTaxBill - 1);
   // }
   //
   //  get valueChange() {
   //    return this.oldValue ? (this.finalValue / this.oldValue - 1) : this.finalValue ? 1 : 0;
   //  }
   //
  // get neighborhood() {
  //   return capitalize(this.data.neighborhood);
  // }
  //
  // get subdivision() {
  //   return capitalize(this.data.subdivision);
  // }
  //
  // get zone() {
  //   return this.data.zone;
  // }
  //
  // get zoneDescription() {
  //   return lookupZoneDescription(this.zone);
  // }

  get useCode() {
    return this.data.useCode;
  }

  // get useDescription() {
  //   return capitalize(this.data.useDescription);
  // }

  get lotSize() {
    return this.data.lotSize;
  }

  get squareFootage() {
    return this.data.squareFootage;
  }

  get lastSalePrice() {
    return this.data.lastSalePrice;
  }

  get lastSaleDate() {
    return this.data.lastSaleDate;
  }

  get yearBuilt() {
    return this.data.yearBuilt;
  }

  // get owners() {
  //   return [this.data.owner1, this.data.owner2].filter(owner => !!owner).map(capitalize);
  // }

  get visionUrl() {
    return `https://gis.vgsi.com/scarboroughme/Parcel.aspx?pid=${this.data.parcelId}`;
  }

  get imageUrl() {
    return `https://gis.vgsi.com/scarboroughme/ParcelSketch.ashx?pid=${this.data.parcelId}&bid=${this.data.parcelId}`;
  }

  // get averageTaxIncrease() {
  //   const history = this.taxBillHistory;
  //   return Math.pow(history[0].bill / history[10].bill, 1 / 10) - 1;
  // }
  //
  // get averageTaxIncrease6Years() {
  //   const history = this.taxBillHistory;
  //   return Math.pow(history[0].bill / history[6].bill, 1 / 6) - 1;
  // }
  //
  // get averageTaxIncrease4Years() {
  //   const history = this.taxBillHistory;
  //   return Math.pow(history[0].bill / history[4].bill, 1 / 4) - 1;
  // }
  //
  // get taxBillHistory() {
  //   let year = taxRates.endYear;
  //   let history = taxRates.taxRates.map(rate => {
  //     let valuation = year <= 2018 ? this.oldValue : this.newValue;
  //     return {
  //       year: year--,
  //       rate,
  //       valuation,
  //       bill: (valuation - this.exemptions) * rate / 1000
  //     };
  //   }).filter(entry => entry.year >= 2008);
  //   console.log(history);
  //   return history;
  // }
}
