<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@media  print {
  .noprint {
    display: none;
  }
}
</style>