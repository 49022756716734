<template>
  <div class="d-flex flex-column h-100">
    <div class="d-flex flex-column flex-grow-1 overflow-auto" ref="scrollContainer">

      <div class="p-4 d-flex flex-row">
        <div>
          <div class="property-address">
            {{ property.address }}
          </div>
          <div class="property-details">
            <div v-for="owner in property.owners" :key="owner">
              {{ owner }}
            </div>
            <div v-if="property.useDescription">
              Use: {{ property.useDescription }}
            </div>
            <div v-if="property.zoneDescription">
              Zone: {{ property.zoneDescription }}
            </div>
            <div v-if="property.subdivision">
              Subdivision: {{ property.subdivision }}
            </div>
            <div v-if="property.lotSize">
              {{ property.lotSize }} acres
            </div>
            <div v-if="property.squareFootage">
              {{ property.squareFootage }} sq. ft.
            </div>
            <div v-if="property.lastSalePrice && property.lastSaleDate">
              Sold for {{ formatCurrency(property.lastSalePrice) }} on {{ property.lastSaleDate }}
            </div>
            <div v-if="property.yearBuilt">
              Built in {{ property.yearBuilt }}
            </div>
          </div>
        </div>
      </div>

      <div class="p-4 border-top">
        <div class="small fw-semibold mb-2">2018 Revaluation</div>
        <div class="mb-2 small">
          The tax bill
          <span v-if="property.taxBillChange < 0" class="text-success"> decreased by </span>
          <span v-else class="text-danger"> increased by </span>
          <span :class="`fw-semibold ${property.taxBillChange < 0 ? 'text-success' : 'text-danger'}`">
          {{ Math.abs(Math.round(100 * property.taxBillChange)) }}%
        </span> from {{ formatCurrency(property.oldTaxBill) }} to {{ formatCurrency(property.newTaxBill) }}.
        </div>
        <div class="small">
          The property valuation
          <span v-if="property.valueChange < 0" class="text-success"> decreased by </span>
          <span v-else class="text-danger"> increased by </span>
          <span :class="`fw-semibold ${property.valueChange < 0 ? 'text-success' : 'text-danger'}`">
          {{ Math.abs(Math.round(100 * property.valueChange)) }}%
        </span> from {{ formatCurrency(property.oldValue) }} to {{ formatCurrency(property.newValue) }}.
        </div>
      </div>

      <div class="p-4 border-top">
        <div class="small fw-semibold mb-2">2024 Revaluation</div>
        <div class="small mb-2">
          The town is beginning the process and plans to mail new valuation notices starting in May 2024. Once this
          data is publicly available, we will update it here.
        </div>
        <div class="small">
          For more info, check out the
          <a href="https://www.scarboroughmaine.org/departments/assessing/property-revaluation" target="_blank">
            town's 2024 revaluation portal
          </a>.
        </div>
      </div>

      <div class="p-4 border-top">
        <div class="fw-semibold small mb-2">Tax Bill History *</div>
        <div class="p-1 small d-flex flex-row align-items-start justify-content-between">
          <div>10-year average annual increase</div>
          <div>{{ Math.round(1000 * property.averageTaxIncrease) / 10 }}%</div>
        </div>
        <div class="p-1 small d-flex flex-row align-items-start justify-content-between">
          <div>
            <div>6-year average annual increase</div>
            <div class="small text-muted">Includes the 2017/2018 commercial/residential revals</div>
          </div>
          <div>{{ Math.round(1000 * property.averageTaxIncrease6Years) / 10 }}%</div>
        </div>
        <div class="p-1 small d-flex flex-row align-items-start justify-content-between">
          <div>
            <div>4-year average annual increase</div>
            <div class="small text-muted">Only includes the years <i>after</i> the 2018 reval</div>
          </div>
          <div>{{ Math.round(1000 * property.averageTaxIncrease4Years) / 10 }}%</div>
        </div>
        <div class="mt-2" style="height:200px">
          <canvas id="tax-history-chart" ref="chart" />
        </div>
        <div class="small text-muted mt-2">
          <div class="small">
            * The tax bills are computed from historical tax rates, plus 2018 and 2019 property value. Because it
            does not reflect the actual tax bill, there may be errors in some cases. If you find an error, please feel
            free to <a target="_blank" href="mailto:reval@scarborough.me">report it</a>. Thanks!
          </div>
        </div>
      </div>
    </div>

    <div class="p-2 d-flex flex-row align-items-top justify-content-around border-top bg-light">
      <a target="_blank" class="d-flex flex-row align-items-center text-decoration-none text-primary btn btn-flat" :href="property.visionUrl">
        <i class="bi-database-fill me-1" />
        <div style="font-size:1rem;" class="text-center">Property Card</div>
      </a>
      <a target="_blank" class="d-flex btn btn-flat flex-row align-items-center text-decoration-none text-primary" :href="`/#/${property.id}`">
        <i class="bi-link me-1" />
        <div style="font-size:1rem;" class="text-center">Link to this property</div>
      </a>
    </div>
  </div>
</template>

<script>
import Property from "@/reval/models/Property";
import Chart from 'chart.js/auto';

export default {
  props: {
    property: Property
  },
  mounted() {
    console.log(`mounted`);
    this.refreshChart();
  },
  unmounted() {
    if (this.chart) {
      this.chart.destroy();
    }
  },
  watch: {
    property() {
      console.log(`property updated`);
      this.refreshChart();
      if (this.$refs.scrollContainer) {
        this.$nextTick(() => {
          this.$refs.scrollContainer.scrollTo({top:0, behavior:'smooth'});
        });
      }
    }
  },
  computed: {
    change() {
      return this.property.taxBillChange;
    },
    summary() {
      const property = this.property;
      let summary = [property.address, property.useDescription];
      if (property.lotSize) summary.push(`${property.lotSize} acres`);
      if (property.squareFootage) summary.push(`${property.squareFootage} sq. ft.`);
      if (property.lastSalePrice && property.lastSaleDate) summary.push(`Sold for ${this.formatCurrency(property.lastSalePrice)} on ${property.lastSaleDate}`);
      if (property.yearBuilt) summary.push(`Built in ${property.yearBuilt}`);
      return summary;
    }
  },
  methods: {
    refreshChart() {
      if (this.$refs.chart) {
        if (!this.chart) {
          this.chart = new Chart(
            this.$refs.chart,
            {
              type: 'bar',
              data: {
                labels: [],
                datasets: [{
                  backgroundColor: '#4171d988',
                  borderColor: '#4171d9',
                  borderWidth: 1,
                  borderRadius: 4,
                  label: 'Tax Bill',
                  data: []
                }]
              },
              options: {
                scales: {
                  y: {
                    ticks: {
                      callback: (value) => {
                        return this.formatCurrency(Math.round(value));
                      }
                    }
                  }
                },
                plugins: {
                  legend: {
                    display: false
                  },
                  tooltip: {
                    position: 'nearest',
                    callbacks: {
                      label: (item) => {
                        return this.formatCurrency(Math.round(item.parsed.y));
                      }
                    }
                  }
                }
              }
            }
          );
        }

        const data = this.property.taxBillHistory.filter(entry => entry.year >= 2005).map(entry => {
          return {
            year: entry.year,
            value: entry.bill
          };
        }).sort((e1, e2) => {
          return e1.year - e2.year;
        });

        let chart = this.chart;
        chart.data.labels.splice(0, chart.data.labels.length, ...data.map(row => row.year));
        chart.data.datasets[0].data.splice(0, chart.data.datasets[0].data.length, ...data.map(row => row.value));
        this.chart.update();

      }
    }
  }
}
</script>

<style>
.property-address, .section-title {
  font-weight: 500;
  color: var(--bs-dark);
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
  //line-height: 1.25rem;
}
.property-details {
  font-weight: 300;
  //color: var(--bs-secondary);
  font-size: 1rem;
  //line-height: 1.25rem;
}
</style>
